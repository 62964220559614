/*
  Bookmark component
 */
const Bookmark = {
  props: {
    jid: String,
    bookmarked: Boolean,
  },
  data() {
    return {
      isBookmarked: false,
    };
  },
  computed: {
    user() {
      return domestiko.user;
    },
  },
  watch: {
    bookmarked(value) {
      this.isBookmarked = value;
    },
  },
  created() {
    this.isBookmarked = !!this.bookmarked;
  },
  methods: {
    toggle() {
      if (!this.user) {
        domestiko.$emit('form.login');
      } else {
        this.isBookmarked ? this.unbookmark() : this.bookmark();
      }
    },
    bookmark() {
      this.isBookmarked = true;
      axios
        .post(`/favoritos/${this.jid}/`)
        .then((response) => response.data)
        .then((data) => {
          //console.log(data);
        });
    },
    unbookmark() {
      this.isBookmarked = false;
      axios
        .delete(`/favoritos/${this.jid}/`)
        .then((response) => response.data)
        .then((data) => {
          //console.log(data);
        });
    },
  },
  template: `
    <button class="uk-button-bookmark" @click="toggle">
      <span :class="{'icon-bookmark-o': !isBookmarked, 'icon-bookmark': isBookmarked }"></span>
      {{ isBookmarked ? 'Descartar oferta guardada' : 'Guardar oferta' }}
    </button>
	`,
};

(function () {
  /*
    Request dispatcher
   */
  let dispatcher = false;
  const items = new Map();
  function dispatch(jid, callback) {
    if (dispatcher) {
      clearTimeout(dispatcher);
    }
    items.set(jid, callback);
    dispatcher = setTimeout(function () {
      axios
        .get(`/favoritos/`, { params: { items: items.keys().toArray() } })
        .then((response) => response.data)
        .then((data) => {
          for (const key in data.bookmarks) {
            items.get(key)(data.bookmarks[key]);
          }
        });
    }, 100);
  }

  /*
  Bookmark component
   */
  const BookmarkComponent = {
    components: {
      Bookmark,
    },
    data() {
      return {
        jid: '',
        bookmarked: false,
      };
    },
    created() {
      domestiko.$on('user.loggedin', this.check);
      console.log('created', this.user, this.jid);
      if (this.jid && this.user) {
        this.check();
      }
    },
    methods: {
      set(jid) {
        this.jid = jid;
      },
      check() {
        if (!this.jid) return;
        dispatch(this.jid, (checked) => {
          this.bookmarked = checked;
        });
      },
    },
    template: `<Bookmark :jid="jid" :bookmarked="bookmarked" />`,
  };

  document.querySelectorAll('.bookmark-widget').forEach((el) => {
    new Vue(BookmarkComponent).$mount(el).set(el.dataset.jid);
  });
})();
